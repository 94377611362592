<template>
  <div class="manager-container module-main-body"  v-loading="fullScreenLoading">
    <el-row class="module-content-top-bar">
      <el-col :md="24" :lg="10">
        <h1 class="top-bar-title">{{contentTitle}}</h1>
      </el-col>
      <el-col :md="24" :lg="14">
        <div class="button-bar ">
          <el-dropdown
            v-if="hasSysAdminPermission"
            @command="handleMore">
            <span class="primary-button" >More Actions</span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="invite_company">
                {{ $t('inviteCompany') }}
              </el-dropdown-item>
              <template v-if="currentCompany.auditStatus === 1">
                <el-dropdown-item
                  command="agree">
                  {{ $t('agreeUpdate') }}
                </el-dropdown-item>
                <el-dropdown-item
                  command="reject">
                  {{ $t('rejectUpdate') }}
                </el-dropdown-item>
              </template>
              <template v-if="currentCompany.active === 2">
                <el-dropdown-item
                  command="active_reject"
                  :disabled="!currentCompany.id">
                  {{ $t('refuseRegister') }}
                </el-dropdown-item>
                <el-dropdown-item
                  command="active_pass"
                  :disabled="!currentCompany.id">
                  {{ $t('acceptRegister') }}
                </el-dropdown-item>
              </template>
              <el-dropdown-item
                command="en_disabled"
                :disabled="!currentCompany.id">
                {{ currentCompany.enable ? $t('lock') : $t('unlock') }}
              </el-dropdown-item>
              <!-- <el-dropdown-item
                style="color: #F56C6C"
                command="delete"
                :disabled="!currentCompany.id">
                {{ $t('delete') }}
              </el-dropdown-item> -->
            </el-dropdown-menu>
          </el-dropdown>
          <span
            v-if="hasPermission($permissionFunctionCode.INVITE_USER) || hasSysAdminPermission"
            class="primary-button"
            @click="inviteUser">
            Add User
          </span>
          <!-- temporarily not used, do not delete -->
          <!-- <el-dropdown
            v-permission="'set_CA'"
            @command="setCA">
            <span class="primary-button">
              {{ $t('setCA') }}<em class="el-icon-arrow-down el-icon--right"></em>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="Netrust CA">Netrust CA</el-dropdown-item>
              <el-dropdown-item command="Bunkerchain CA">Bunkerchain CA</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown> -->
          <span
            v-if="hasSysAdminPermission"
            class="primary-button"
            @click="handleUpdateHistory">
            {{ $t('updateHistory') }}
          </span>
        </div>
      </el-col>
    </el-row>
    <el-row class="div-main-body-left-right module-content-main-view" v-if="!fullScreenLoading">
      <el-col  :sm="hasSysAdminPermission ? 10 : 0" :md="hasSysAdminPermission ? 8 : 0" :lg="hasSysAdminPermission ? 5 : 0" class="left">
        <div class="title">
          <base-block-title
            :title="$t('company')"
            :sub-title="companyList.length">
          </base-block-title>
          <div>
            <el-dropdown
              v-if="hasSysAdminPermission"
              @command="handleMore">
              <span class="el-dropdown-link">
                <el-button
                  type="text"
                  icon="el-icon-more">
                </el-button>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="invite_company">
                  {{ $t('inviteCompany') }}
                </el-dropdown-item>
                <template v-if="currentCompany.auditStatus === 1">
                  <el-dropdown-item
                    command="agree">
                    {{ $t('agreeUpdate') }}
                  </el-dropdown-item>
                  <el-dropdown-item
                    command="reject">
                    {{ $t('rejectUpdate') }}
                  </el-dropdown-item>
                </template>
                <template v-if="currentCompany.active === 2">
                  <el-dropdown-item
                    command="active_reject"
                    :disabled="!currentCompany.id">
                    {{ $t('refuseRegister') }}
                  </el-dropdown-item>
                  <el-dropdown-item
                    command="active_pass"
                    :disabled="!currentCompany.id">
                    {{ $t('acceptRegister') }}
                  </el-dropdown-item>
                </template>
                <el-dropdown-item
                  command="en_disabled"
                  :disabled="!currentCompany.id">
                  {{ currentCompany.enable ? $t('lock') : $t('unlock') }}
                </el-dropdown-item>
                <!-- <el-dropdown-item
                  style="color: #F56C6C"
                  command="delete"
                  :disabled="!currentCompany.id">
                  {{ $t('delete') }}
                </el-dropdown-item> -->
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
        <el-divider class="el-divider"></el-divider>
        <div class="search-bar">
          <el-input
            class="round-input"
            placeholder="Search Company Name"
            v-model="searchKey"
            @keypress.enter.native="onHandleSearch"
          >
            <el-button v-if="!toggleIcon" type="primary" slot="append" icon="el-icon-search" @click="onHandleSearch"></el-button>
            <el-button v-if="toggleIcon" type="primary" slot="append" icon="el-icon-close" @click="onHandlClearSearch"></el-button>
          </el-input>
        </div>
        <div class="content">
          <company-item-new
            v-for="item in companyList"
            :key="item.id"
            :company="item"
            :currentCompany="currentCompany"
            :logoSrc.sync="item.logoSrc"
            :showStatusTage="true"
            :active="item.id === currentCompany.id"
            @click.native="currentCompany = item">
          </company-item-new>
        </div>
      </el-col>
      <el-col :sm="hasSysAdminPermission ? 14 : 24" :md="hasSysAdminPermission ? 16 : 24" :lg="hasSysAdminPermission ? 19 : 24"
        class="right"
        v-if="pageVisible && currentCompany.id">
        <el-col :xs="24" :sm="8" :md="6" :lg="4" class="company-logo-name"   type="flex"  align="middle">
          <img
          v-if="currentCompany.logoSrc && currentCompany.logoSrc!==''"
          height="100px"
          width="100px"
          :src="currentCompany.logoSrc"
          alt=""/>
          <el-avatar v-else :size="100">
          <span class="company-info-avatar">{{currentCompany ? getAvatarName(currentCompany.companyName) : ''}}</span></el-avatar>
        </el-col>
        <el-col :xs="24" :sm="16" :md="18" :lg="20">
          <el-col :xs="24" :sm="24" :md="16" :lg="18" class="company-info">
            <el-row class="company-info-detail" >
              <el-row type="flex" justify="space-between" align="middle" class="sub-title-bar">
                <el-col :xs="24" :sm="hasSysAdminPermission ? 16 : 10" :md="hasSysAdminPermission ? 16 : 10" :lg="hasSysAdminPermission ? 16 : 10">
                  <div>
                    <span class="sub-title-text">Company Overview</span>
                    <el-tag  class="botton-bar-tag" type="warning" style="margin-left:10px;"
                      v-if="(currentCompany.auditStatus === 1 && hasPermission($permissionFunctionCode.EDIT))">
                      {{this.$t('updateAuditing')}}
                    </el-tag>
                  </div>
                </el-col>
                <el-col :xs="24" :sm="hasSysAdminPermission ? 8 : 14"  :md="hasSysAdminPermission ? 8 : 14"  :lg="hasSysAdminPermission ? 8 : 14" >
                  <span
                    v-if="hasPermission($permissionFunctionCode.EDIT)"
                    style="float:right"
                    class="second-button"
                    @click="openAttachments">
                    {{ this.$t('Attachment') }}
                  </span>
                  <span
                    style="float:right"
                    v-if="(hasPermission($permissionFunctionCode.EDIT))"
                    class="primary-button"
                    @click="editCompany('basic')">
                    {{ this.$t('edit') }}
                  </span>
                  <span
                    v-if="hasPermission($permissionFunctionCode.EDIT)"
                    style="float:right"
                    class="second-button"
                    @click="editCompany('settings')">
                    Settings
                  </span>
                </el-col>
              </el-row>
              <el-col :xs="12" :sm="12" :md="8" :lg="8" :xl="6"
                v-for="(key) in overviewFields"
                :key="key" class="margin-5-0">
                <h4 >
                  {{ $t(key) }}:
                </h4>
                <p >
                  {{ !!currentCompany[key] ? currentCompany[key] : " - "}}
                </p>
              </el-col>
              <el-col :sm="24" :md="24" class="margin-top-10">
                <span  class="sub-title-text">Company Address</span>
              </el-col>
              <el-col :xs="12" :sm="12" :md="8" :lg="8" :xl="6"
                v-for="(key) in addressFields"
                :key="key"  class="margin-5-0">
                <h4 >
                  {{ $t(key) }}:
                </h4>
                <p >
                  {{ !!currentCompany[key] ? currentCompany[key] : " - "}}
                </p>
              </el-col>
              <el-col :sm="24" :md="24" class="margin-top-10">
                <span  class="sub-title-text">API Information</span>
              </el-col>
              <el-col :xs="12" :sm="12" :md="8" :lg="8" :xl="6"
                v-for="(key) in apiFields"
                :key="key"  class="margin-5-0">
                <h4 >
                  {{ $t(key) }}:
                </h4>
                <p v-if="key === 'publicKey'">
                  <el-tooltip
                    class="custom-tooltip"
                    effect="dark"
                    :content="currentCompany.publicKey"
                    placement="top">
                    <span>{{ truncatedPublicKey }}</span>
                  </el-tooltip>
                </p>
                <p v-else-if="key === 'apiKey'">
                  <el-tooltip
                    class="custom-tooltip"
                    effect="dark"
                    :content="currentCompany.apiKey"
                    placement="top">
                    <span>{{ truncatedApiKey }}</span>
                  </el-tooltip>
                </p>
                <p v-else>
                  {{ !!currentCompany[key] ? currentCompany[key] : " - "}}
                </p>
              </el-col>
              <el-col  :sm="24" :md="24" v-if="currentCompany?.bdnEmailTitle&& currentCompany?.bdnEmailTitle!=''">
                <h4 >{{ $t('bdnEmailTitle') }}:</h4>
                <p > {{  currentCompany?.bdnEmailTitle ??  " - "}} </p>
              </el-col>
            </el-row>
          </el-col>
          <el-col :xs="24" :sm="24" :md="8" :lg="6" class="bank-info">
            <el-row type="flex" justify="space-between" align="middle" class="sub-title-bar">
              <el-col :xs="24" :sm="hasSysAdminPermission ? 24 :12" :md="hasSysAdminPermission ?24:12" :lg="hasSysAdminPermission ?24:12">
                <span  class="sub-title-text" >Bank Information</span>
              </el-col>
              <el-col  v-if="!hasSysAdminPermission" :xs="24" :sm="12" :md="12" :lg="12">
                <span
                style="float:right"
                class="primary-button"
                v-if="hasPermission($permissionFunctionCode.EDIT)"
                @click="editCompany('bank')">
                {{ this.$t('edit') }}
                </span>
              </el-col>
            </el-row>
              <span  class="bank-info-detail"
                v-for="(key) in bankFields"
                :key="key">
                <h4 >
                  {{ $t(key) }}:
                </h4>
                <p v-if="hasSysAdminPermission">
                  {{ activeCompanyBankInfo && !!activeCompanyBankInfo[key.slice(5)] ? activeCompanyBankInfo[key.slice(5)] : " - "}}
                </p>
                <p >
                  {{ companyDefaultBank && !!companyDefaultBank[key.slice(5)] ? companyDefaultBank[key.slice(5)] : " - "}}
                </p>
              </span>
          </el-col>
        </el-col>
        <el-col :lg="24" class="users">
          <user-card
            v-for="user in users"
            :key="user.id"
            :user="user"
            :company="currentCompany"
            @handle-success="getUserListByCompany">
          </user-card>
        </el-col>
        <base-no-data v-if="!currentCompany.id"></base-no-data>
      </el-col>
    </el-row>
    <update-company
      v-if="editCompanyVisible"
      :infoType='editCompanytype'
      :title='editCompanyTitle'
      :original-value="currentCompany"
      @closed="editCompanyVisible=false"
      @closeDialog="closeDialog"
      @submit-success="getCompanyInfoById">
    </update-company>
    <invite-member
      v-if="inviteVisible"
      :company-id="currentCompany.id"
      @closed="inviteVisible=false">
    </invite-member>
    <update-history
      v-if="updateHistoryVisible"
      :company="currentCompany"
      @closed="updateHistoryVisible=false">
    </update-history>
     <el-dialog
      :visible.sync="attachmentsVisible"
      :close-on-click-modal="false"
      top="12vh"
      width="800px"
      @close="closeAttachments">
      <div class="dialog-container">
        <div class="top-bar">
          <h1>Attachment</h1>
        </div>
        <div v-if="fileList.length>0" class="attachments-container">
          <div
            class="attachment-item"
            v-for="item in fileList"
            :key="item.id">
            <span class="attachment-name">{{ item.originalName || item.name }}</span>
            <div class="button-group">
              <el-button
                round
                plain
                size="small"
                type="primary"
                @click="previewFileMixin(item.id)"
              >View</el-button>
            </div>
          </div>
        </div>
        <div class="no-data" v-else>
          <img src="@/assets/images/no_data.png" alt="">
          <span>{{ $t('noDataAlt') }}</span>
        </div>
        <span class="dialog-footer flex-end">
          <el-button
            type="primary"
            round
             @click="closeAttachments"
            >
            Close
          </el-button>
      </span>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import baseMixin from '@/mixins/base-mixin'
import baseTableMixin from '@/mixins/base-table-mixin'
import UserCard from '@/components/UserCard'
import UpdateCompany from './components/UpdateCompany'
import InviteMember from './components/InviteMember'
import UpdateHistory from './components/UpdateHistory'
import { isValidString } from '@/utils/index.js'

export default {
  name: 'OrganizationManager',
  mixins: [baseMixin, baseTableMixin],
  components: { UserCard, UpdateCompany, InviteMember, UpdateHistory },
  data () {
    return {
      contentTitle: 'Company Management',
      overviewFields: [
        'incorporationCountry',
        'businessIncorporationNumber',
        'incorporationDate',
        'fax',
        'phone',
        'email',
        'companyType',
        'licenseNumber'
      ],
      addressFields: [
        'cityOrState',
        'streetName',
        'buildingName',
        'unitNumber',
        'postal'
      ],
      apiFields: [
        'publicKey',
        'apiKey',
        'apiName'
      ],
      userFields: [
        'email',
        'firstName',
        'lastName',
        'homePhoneNumber',
        'salutation',
        'officePhoneNumber',
        'designation'
      ],
      bankFields: [
        'bank_name',
        'bank_swiftCode',
        'bank_accountNo',
        'bank_accountType',
        'bank_country'
      ],
      companyList: [],
      currentCompany: {},
      users: [],
      editCompanyVisible: false,
      pageVisible: true,
      inviteVisible: false,
      updateHistoryVisible: false,
      searchKey: null,
      editCompanytype: '',
      editCompanyTitle: '',
      toggleIcon: false,
      attachmentsVisible: false,
      fullScreenLoading: false
    }
  },

  computed: {
    ...mapState(['bankList', 'companyDefaultBank', 'roles', 'permissions']),
    fileList () {
      const fileFields = [
        'businessIncorporationDocument',
        'authorizationLetter',
        'operatorLicences',
        'craftOperatorLicences',
        'bdnLogo',
        'otherDocument'
      ]
      let list = []
      if (this.currentCompany.id) {
        fileFields.forEach(key => {
          list = list.concat(this.currentCompany[key] || [])
        })
      }
      return list
    },
    hasSysAdminPermission () {
      return this.permissions.hasSysAdminPermission
    },
    activeCompanyBankInfo () {

    },
    truncatedPublicKey() {
      const publicKey = this.currentCompany.publicKey;
      if (publicKey && publicKey.length > 20) {
        return `${publicKey.substring(0, 20)}...`;
      }
      return publicKey;
    },
    truncatedApiKey() {
      const apiKey = this.currentCompany.apiKey;
      if (apiKey && apiKey.length > 20) {
        return `${apiKey.substring(0, 20)}...`;
      }
      return apiKey;
    }
  },

  created () {
    this.saveUrl = this.$apis.saveCompany
    this.deleteUrl = this.$apis.deleteCompany
    this.getCompanies()
  },
  methods: {
    isValidString,
    /**
     * 根据当前用户获取企业列表
     */
    getCompanies () {
      const fetchFiles = async () => {
        if(this.companyList.length <= 0) return
        this.currentCompany = this.companyList[0]
        const files = this.companyList.filter(d => d?.bdnLogo?.length > 0).map(d => d.bdnLogo[0])
        if (files.length <= 0) return
        this.downloadMultipleFileMixin(files).then(filesRes => {
          if (filesRes && filesRes !== '') {
            const logoMap = new Map(Object.entries(filesRes))
            this.companyList.forEach(company => {
              if (company?.bdnLogo?.length > 0) {
                company.logoSrc = company.bdnLogo[0].id ? logoMap.get(company.bdnLogo[0]?.id) : null
              }
            })
          }
        })
      }

      return new Promise((resolve, reject) => {
        this.fullScreenLoading = true
        if(this.hasSysAdminPermission) {
          this.$request.get({
            url: `${this.$apis.companyBaseUrl}?pageNumber=1&pageSize=99999&offline=false`,
          }).then(res => {
            if (res?.code === 1000) {
              this.companyList = res?.data?.records || []
              fetchFiles().then(() => {
                this.fullScreenLoading = false
                resolve()
              })
            }
          })
        }
        else {
          const { currentCompany: globalCurrentCompany } = this.$store.state
          this.$request.get({
            url: `${this.$apis.companyBaseUrl}/${globalCurrentCompany.id}`,
          }).then(res => {
            if (res?.code === 1000) {

              this.companyList = res?.data ? [res?.data] : []

              fetchFiles().then(() => {
                this.fullScreenLoading = false
                resolve()
              })
            }
          })
        }
      })
    },
    getCompanyInfoById () {
      /**
       * 根据企业ID获取企业信息
       */
      this.getCompanies().then(() => {
        this.currentCompany = this.companyList.find(item => item.id === this.currentCompany.id)
      })
    },
    getCompanyB () {
      /**
       * 根据企业ID获取企业信息
       */
      this.getCompanies().then(() => {
        this.currentCompany = this.companyList.find(item => item.id === this.currentCompany.id)
      })
    },

    formatUser (users) {
      /**
       * 格式化排序用户列表，把当前用户排到第一位
       */
      users.forEach(item => {
        item.roles = item.roles ?? []
      })
      const { id } = this.$store.state.user
      const currentUserIndex = users.findIndex(item => item.id === id)
      if (currentUserIndex) {
        users.unshift(users[currentUserIndex])
        users.splice(currentUserIndex + 1, 1)
      }
      this.users = users
    },

    getUserListByCompany () {
      /**
       * 根据机构获取用户列表
       */
      this.$request.get({
        url: `${this.$apis.userExtBaseUrl}?pageNumber=1&pageSize=99999&organizationId=${this.currentCompany.id}`,
      }).then(res => {
        if (res?.code === 1000) {
          // 过滤角色是船的用户
          const users = (res?.data?.records || []).filter(item => {
            const roles = item.roles || []
            return !(roles.length === 1 && roles[0].code === this.$roleCode.ship)
          })
          this.formatUser(users)
        }
      })
    },

    deleteCompany () {
      /**
       * 删除企业
       */
      this.$request.post({
        url: this.$apis.deleteCompany,
        data: [this.currentCompany.id]
      }).then(res => {
        if (res?.code === 1000) {
          this.currentCompany = {}
          this.getCompanies()
          this.$message.success(this.$t('dataDeleteSuccess'))
        }
      })
    },

    enableDisableCompany () {
      /**
       * 启用或禁用企业
       */
      this.$request.post({
        url: this.$apis.enableOrDisableCompany,
        data: {
          organization: {
            id: this.currentCompany.id,
            enable: !this.currentCompany.enable
          }
        }
      }).then(res => {
        if (res?.code === 1000) {
          const dataDisabledSuccess = 'User has been deactivated'
          const dataEnableSuccess = 'User has been activated'
          this.$message.success(this.currentCompany.enable ? dataDisabledSuccess : dataEnableSuccess)
          this.getCompanies().then(() => {
            this.currentCompany = this.companyList.find(item => item.id === this.currentCompany.id)
          })
        }
      })
    },

    approvalRegistration (active) {
      /**
       * 审批企业注册
       */
      return () => {
        const request = (active, activeComment = undefined) => {
          this.$request.post({
            url: this.$apis.enableOrDisableCompany,
            data: {
              organization: {
                id: this.currentCompany.id,
                active,
                activeComment
              }
            }
          }).then(res => {
            if (res?.code === 1000) {
              this.$message.success(this.$t('operationSuccess'))
              this.getCompanies().then(() => {
                this.currentCompany = this.companyList.find(item => item.id === this.currentCompany.id)
              })
            }
          })
        }
        if (active === 0) {
          this.$prompt(this.$t('rejectReasonTip'), this.$t('rejectCompanyRegistration'), {
            confirmButtonText: this.$t('submit'),
            cancelButtonText: this.$t('cancel'),
            inputType: 'textarea',
            inputPattern: /.+/,
            inputErrorMessage: this.$t('rejectBeEmpty')
          }).then(({ value }) => {
            request(active, value)
          }).catch(() => {
          })
        } else {
          request(active)
        }
      }
    },

    auditUpdate (auditStatus, auditMessage = undefined) {
      /**
       * 审核申请更新
       */
      return () => {
        this.$request.post({
          url: this.$apis.auditUpdateCompany,
          data: {
            tableId: this.currentCompany.id,
            status: auditStatus,
            comment: auditMessage
          }
        }).then(res => {
          if (res?.code === 1000) {
            const message = `Updates to ${this.currentCompany.companyName} company information has been approved.`
            this.$message.success(message)
            // this.getCompanies().then(() => {
            //   this.currentCompany = this.companyList.find(item => item.id === this.currentCompany.id)
            // })
            this.$router.go(0)
          }
        })
      }
    },
    inviteCompany (email) {
      /**
       * 邀请企业
       */
      this.$request.post({
        url: this.$apis.inviteCompany,
        data: {
          email
        }
      }).then(res => {
        if (res?.code === 1000) {
          this.$message.success(this.$t('operationSuccess'))
        }
      })
    },

    handleMore (command) {
      /**
       * 更多操作
       */
      if (['reject', 'invite_company'].includes(command)) {
        // 拒绝修改和邀请企业的操作
        const tip = {
          reject: this.$t('rejectReasonTip'),
          invite_company: this.$t('email')
        }
        const tipHeader = {
          reject: this.$t('rejectCompanyRegistration'),
          invite_company: this.$t('inviteCompany')
        }
        this.$prompt(tip[command], tipHeader[command], {
          confirmButtonText: this.$t('confirm'),
          cancelButtonText: this.$t('cancel'),
          inputPattern: /.+/,
          inputErrorMessage: this.$t('rejectBeEmpty')
        }).then(({ value }) => {
          command === 'reject' ? this.auditUpdate(2, value)() : this.inviteCompany(value)
        }).catch(() => {
        })
      } else if (['en_disabled', 'delete', 'agree', 'active_pass'].includes(command)) {
        const tip = {
          en_disabled: this.currentCompany.enable ? this.$t('disabledTip') : this.$t('enableTip'),
          delete: this.$t('deleteTip'),
          agree: this.$t('agreeUpdateCompany'),
          active_pass: this.$t('acceptRegisterTip')
        }
        const tipHeader = {
          en_disabled: this.currentCompany.enable ? this.$t('deactivateCompany') : this.$t('activateCompany'),
          delete: this.$t('deleteCompany'),
          agree: this.$t('acceptCompanyUpdate'),
          active_pass: this.$t('approveCompanyRegistration')
        }
        this.$confirm(tip[command], tipHeader[command], {
          confirmButtonText: this.$t('confirm'),
          cancelButtonText: this.$t('cancel'),
          type: 'warning'
        }).then(() => {
          const doList = {
            en_disabled: this.enableDisableCompany,
            delete: this.deleteCompany,
            agree: this.auditUpdate(3),
            active_pass: this.approvalRegistration(1)
          }
          doList[command]()
        }).catch(() => {
        })
      } else if (command === 'active_reject') {
        this.approvalRegistration(0)()
      }
    },

    editCompany (type) {
      /**
       * 编辑企业,auditStatus与后端约定,0:初始状态,1:审批中,2:拒绝,3:通过
       */
      this.editCompanytype = type
      if (this.currentCompany.auditStatus !== 1) {
        // 非审批中的状态可以先修改企业信息
        this.editCompanyVisible = true
        if (type === 'basic') {
          this.editCompanyTitle = 'Basic Information'
        }
      } else {
        this.$message({ type: 'warning', message: 'Your company is pending approval now.'})
      }
      /**
      * 编辑企业银行信息不需要审批，也不受状态控制
      */
      if (type === 'bank') {
        this.editCompanyVisible = true
        this.editCompanyTitle = 'Bank Information'
      }

      if (type === 'settings') {
        this.editCompanyVisible = true
        this.editCompanyTitle = 'Company Settings'
      }
    },

    setCA (CAType) {
      /**
       * 设置CA证书
       * @param {String} CAType:CA证书类型
       */
      const message = `${this.$t('inputAuthenticationCode')}(${CAType})`
      const field = {
        'Netrust CA': 'netrustCa',
        'Bunkerchain CA': 'bunkerchainCa'
      }
      const inputValue = this.currentCompany[field[CAType]]
      this.$prompt(message, this.$t('setCA'), {
        confirmButtonText: this.$t('submit'),
        cancelButtonText: this.$t('cancel'),
        inputValue,
        inputPattern: /.+/,
        inputErrorMessage: this.$t('inputAuthenticationCode')
      }).then(({ value }) => {
        const data = {
          id: this.currentCompany.id
        }
        data[field[CAType]] = value
        this.$request.post({
          url: this.$apis.saveCompany,
          data
        }).then(res => {
          if (res?.code === 1000) {
            this.getCompanyInfoById()
            this.$message.success(this.$t('CASetSuccess'))
          }
        })
      }).catch(() => {
      })
    },

    inviteUser () {
      if (!this.currentCompany.id) {
        return this.$message.error(this.$t('selectCompany'))
      }
      this.inviteVisible = true
      return
    },
    // 查看更新历史
    handleUpdateHistory () {
      this.updateHistoryVisible = true
    },
    onHandleSearch () {
      const key = this.searchKey
      this.getCompanies().then(() => {
        if (this.isValidString(key) && this.companyList?.length > 0) {
          const companyListSearched = this.companyList.filter(d => d.companyName.toLowerCase().includes(this.searchKey.toLowerCase()))
          this.companyList = companyListSearched
          this.toggleIcon = !this.toggleIcon
        } else {
          this.toggleIcon = false
        }
      })
    },
    onHandlClearSearch () {
      this.getCompanies().then(() => {
        this.toggleIcon = !this.toggleIcon
        this.searchKey = ''
      })
    },
    onViewAttachments () {
      alert('popup')
    },
    openAttachments () {
      this.attachmentsVisible = true
    },
    closeAttachments () {
      this.attachmentsVisible = false
    },
    closeDialog (type) {
      if (type === 'bank') {
        this.$store.dispatch('getBankList')
      }
    },

  },

  watch: {
    'currentCompany.id': {
      handler (value) {
        if (value) {
          this.getUserListByCompany()
          // 重新获取当前用户的权限
          const { id: userId } = this.$store.state.user
          this.getFunctionsByUser(userId, value)
          this.$store.dispatch('getBankList', value)
        }
      },
      immediate: true
    },
    '$route' (to) {
      const { companyId } = to.params
      this.currentCompany = this.companyList.find(item => item.id === companyId) || {}
    }
  }
}
</script>

<style lang="scss" scoped>
@import "index";
.company-info-avatar{
  font-size: 30px;
  font-weight: 900;
}
</style>
